'use client';
import React from 'react';
import HeroSection from '@/components/landing/HeroSection';
import OurServices from '@/components/landing/OurServices';
import JoinCommunity from '@/components/landing/JoinCommunity';
import Faq from '@/components/landing/Faq';
import Stats from '@/components/landing/Stats';
import Link from 'next/link';
import { useSession } from 'next-auth/react';

const LandingLayout = () => {
  const { data: session, status } = useSession();

  return (
    <>
      <HeroSection session={session} status={status} />
      <div className="fixed bottom-10 right-10 z-[100]">
        <Link
          href="/organize-event"
          className="px-3 py-3 rounded-2xl shadow-xl bg-black text-white z-50 hover:shadow-2xl transition duration-300 ease-in-out"
        >
          Request Demo
        </Link>
      </div>
      <OurServices />
      <JoinCommunity />
      <Stats />
      <Faq />
    </>
  );
};

export default LandingLayout;
