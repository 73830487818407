'use client';
import React, { useState, useLayoutEffect } from 'react';
import { useSession } from 'next-auth/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Button } from '@nextui-org/react';
import EventCard from '@/components/events/EventCard';
import Link from 'next/link';
import Loading from '@/components/Loading';
import axios from 'axios';
import { baseURL } from '@/utils/BaseURL';

const UpcomingCards = () => {
  const { data: session, status } = useSession();

  const [opportunities, setOpportunities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useLayoutEffect(() => {
    let headers = {};
    if (status === 'authenticated')
      headers = {
        Authorization: `Bearer ${session?.accessToken}`,
      };

    const params = {
      page: 1,
      page_size: 20,
      ordering: '-updated_at',

      is_verified: true,
      is_active: true,
    };

    axios
      .get(`${baseURL}/opportunity/list/`, {
        headers,
        params,
      })
      .then(response => {
        setOpportunities(response.data.data);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [session, status]);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="my-10 md:my-5">
      <div className="layoutBox">
        <h2 className="text-3xl mt-2 text-center font-medium pb-4">
          Our Events
        </h2>
        <div className="w-full ">
          <Swiper
            spaceBetween={5}
            slidesPerView={1}
            pagination={{
              clickable: true,
            }}
            className=""
            navigation
            modules={[Navigation, Pagination]}
            centeredSlides={false}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 3,
              },
              620: {
                slidesPerView: 2,
                spaceBetween: 3,
              },
              1000: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1410: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              1500: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
          >
            {opportunities?.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="flex justify-center py-12 px-8">
                  <EventCard opportunity={item} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="flex justify-center my-5">
          <Button variant="flat" color="primary" as={Link} href={'/events'}>
            Explore more
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UpcomingCards;
